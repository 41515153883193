import { useEffect, useState } from "react";
import { ErrorMessages, Validations } from "./data";
import { EMAIL_REGEX } from "./regex";

const useInput = (props) => {
  const { value, type, repeatField, validation, readOnly } = props;
  const [focus, setFocus] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isMounted, setIsMounted] = useState(false);

  const TriggerInputEvent = () => {
    if (typeof window !== "undefined") {
      const event = new Event("focusedInput");
      window.dispatchEvent(event);
    }
  };

  useEffect(() => {
    if (typeof repeatField === "undefined") return;
    if (!focus && !isMounted) return;
    if (!isMounted) return setIsMounted(true);
    ValidateInput(props);
  }, [repeatField]);

  useEffect(() => {
    if (!isMounted) return setIsMounted(true);
    if (!value) return;
    ValidateInput(props);
  }, [validation]);

  useEffect(() => {
    if (props.error && !error) {
      setError(ErrorMessages.required);
    }
  }, [props.error]);

  useEffect(() => {
    if (error) setError("");
  }, [value]);

  const ValidateInput = (props) => {
    const { required, value, type, repeatField, repeatError, validation } =
      props;

    const isRequiredAndNotFilled = required && !value;
    const isInvalidEmaild = !EMAIL_REGEX.test(value);
    const isRepeatField = repeatField;
    let isNotValid = false;

    if (validation && Validations[validation]) {
      const validationRegex = Validations[validation];
      isNotValid = !validationRegex.test(value);
    }

    if (isRequiredAndNotFilled) return setError(ErrorMessages.required);
    if (type === "email" && isInvalidEmaild)
      return setError(ErrorMessages.email);
    if (isNotValid) return setError(ErrorMessages[validation]);
    if (isRepeatField) return setError(ErrorMessages[repeatError]);
    if (error) setError("");
  };

  const HandleOnBlur = (props) => {
    if (focus) setFocus(false);
    ValidateInput(props);
  };

  let isFocused = false;

  if (value || focus) {
    isFocused = true;
  } else {
    isFocused = false;
  }
  if (readOnly) {
    if (value) {
      isFocused = true;
    } else {
      isFocused = false;
    }
  }
  if (type === "date") isFocused = true;

  return {
    focus,
    setFocus,
    error,
    TriggerInputEvent,
    HandleOnBlur,
    isFocused,
    showPassword,
    setShowPassword,
  };
};

export default useInput;
