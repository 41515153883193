import { useEffect } from "react";
import moment from "moment-timezone";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker as SingleDatePickerComponent } from "react-dates";

import "./index.scss";

const SingleDatePicker = ({
  date,
  onDateChange,
  focused,
  setFocused,
  minValueInYears,
  maxValueInYears,
  minValue,
  maxValue,
  birthdate = false,
  ageBasis,
  periodRanges,
}) => {
  const isOutsideRange = (day) => {
    const currentDate = moment(day);
    let dayIsBlocked = true;
    let basis = moment();
    if (ageBasis) basis = moment(ageBasis);
    if (minValueInYears) {
      const minValueDate = moment(basis).subtract(minValueInYears, "y");
      if (currentDate.isSameOrBefore(minValueDate)) {
        dayIsBlocked = false;
      }
    }

    if (maxValueInYears) {
      const maxValueDate = moment(basis).subtract(maxValueInYears, "y");
      if (currentDate.isSameOrAfter(maxValueDate)) {
        dayIsBlocked = false;
      }
    }

    if (periodRanges && periodRanges.length > 0) {
      dayIsBlocked = true;
      const eachDay = moment(day.format("YYYY-MM-DD")).startOf("day");
      periodRanges.forEach((range) => {
        const from = moment(range.from).startOf("day").startOf("day");
        const to = moment(range.to).startOf("day");
        if (
          eachDay.isSameOrAfter(from) &&
          eachDay.isSameOrBefore(to) &&
          range[eachDay.clone().format("ddd").toLowerCase()] &&
          eachDay.isSameOrAfter(moment().startOf("day"))
        ) {
          dayIsBlocked = false;
        }
      });
    }

    if (minValue || maxValue) {
      let currentIsAfterMin = true;
      let currentIsBeforeMax = true;

      if (minValue) {
        if (currentDate.isSame(minValue, "day")) {
          const currentHour = moment().hour();
          const maxHour = minValue.get("hour");
          // this may seem contradictory, but it's just to prevent buying
          // "same-day" lift tickets after the lift ticket "closing hour"
          if (currentHour >= maxHour) currentIsAfterMin = false;
        } else {
          currentIsAfterMin = currentDate.isAfter(minValue);
        }
      }
      if (maxValue) currentIsBeforeMax = currentDate.isBefore(maxValue);
      if (currentIsAfterMin && currentIsBeforeMax) {
      } else {
        dayIsBlocked = true;
      }
    }

    return dayIsBlocked;
  };

  const returnYears = () => {
    let years = [];
    const maxValueYears = maxValueInYears ? maxValueInYears : 100;
    const minValueYears = minValueInYears ? minValueInYears : 0;
    for (
      let i = moment().year() - maxValueYears;
      i <= moment().year() - minValueYears;
      i++
    ) {
      years.push(
        <option value={i} key={`year_${i}`}>
          {i}
        </option>
      );
    }
    return years;
  };

  const config = {};
  if (birthdate) {
    config.renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
      <div className="selectsWrapper">
        <div>
          <select
            value={month.month()}
            onChange={(e) => onMonthSelect(month, e.target.value)}
            className={"select"}
          >
            {moment.months().map((label, value, idx) => {
              return (
                <option value={value} key={`${label}_${value}`}>
                  {label}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <select
            value={month.year()}
            onChange={(e) => onYearSelect(month, e.target.value)}
            className={"select"}
          >
            {returnYears()}
          </select>
        </div>
      </div>
    );
    config.navNext = () => <span />;
    config.navPrev = () => <span />;
  }

  if (
    minValueInYears ||
    maxValueInYears ||
    minValue ||
    maxValue ||
    periodRanges
  ) {
    config.isOutsideRange = (day) => {
      return isOutsideRange(day);
    };
  }

  const getDate = (date) => {
    return moment(date, "DD/MM/YYYY");
  };

  const getDefaultDate = () => {
    if (minValueInYears) {
      return moment().subtract(minValueInYears, "y");
    }
    // if (periodRanges) {
    //   const currentDay = moment().format("YYYY-MM-DD");
    //   let minRange = undefined;
    //   let minPeriodRange = {};
    //   periodRanges.forEach((pr) => {
    //     if (
    //       (!minRange && pr.from >= currentDay) ||
    //       (pr.from < minRange && pr.from >= currentDay)
    //     ) {
    //       minRange = pr.from;
    //       minPeriodRange = pr;
    //     }
    //   });

    //   if (minRange) {
    //     for (let index = 0; index < 7; index++) {
    //       const dayOfWeek = moment(minRange)
    //         .add(index, "days")
    //         .format("ddd")
    //         .toLowerCase();
    //       if (minPeriodRange[dayOfWeek]) {
    //         minRange = moment(minRange).add(index, "days");
    //         break;
    //       }
    //     }
    //   }

    //   return moment(minRange);
    // }
    return undefined;
  };

  const defaultDate = getDefaultDate();

  return (
    <SingleDatePickerComponent
      firstDayOfWeek={1}
      calendarInfoPosition="top"
      hideKeyboardShortcutsPanel={true}
      date={date ? getDate(date) : defaultDate}
      onDateChange={onDateChange}
      focused={focused}
      onFocusChange={({ focused }) => setFocused(focused)}
      id="your_unique_id"
      // eslint-disable-next-line
      numberOfMonths={1}
      readOnly={true}
      {...config}
    />
  );
};

export default SingleDatePicker;
