import React from "react";
import { LocaleAsString } from "../../../localeEngine";
import Icon from "../../icons";
import useInput from "./logic";
import { Typography, Button } from "../../utils";
import { InputProps } from "./types";
import * as styles from "./styles.module.scss";

const InputComponent = (props: InputProps) => {
  const {
    className,
    label,
    icon,
    required,
    name,
    value,
    type = "text",
    onChange,
    disablePaste,
    showIcon = true,
    readOnly = false,
    autoComplete = false,
    errorMessage = "",
    id,
  } = props;
  const {
    focus,
    setFocus,
    error,
    TriggerInputEvent,
    HandleOnBlur,
    isFocused,
    showPassword,
    setShowPassword,
  } = useInput(props);

  const InputLogicConfig = {};
  if (readOnly) InputLogicConfig.tabIndex = "-1";
  if (autoComplete) InputLogicConfig.autoComplete = autoComplete;
  if (id) InputLogicConfig.id = id;

  return (
    <div className={`${styles.inputWrapper} ${className}`}>
      <div
        className={`${styles.input} ${!showIcon && styles.inputWithoutIcon} ${
          error && styles.error
        }`}
      >
        {showIcon && (
          <Icon icon={icon} size={"Small"} className={styles.icon} />
        )}
        <Typography
          component="label"
          variant={"h300-medium"}
          for={name}
          className={`${styles.label} ${isFocused && styles.focused}`}
        >
          {label}
          {required && (
            <>
              {" "}
              <span title="required" aria-label="required">
                *
              </span>
            </>
          )}
        </Typography>
        <Typography
          component="input"
          variant={"h400-medium"}
          className={styles.textfield}
          inputLogic={{
            readOnly: readOnly ? "readOnly" : false,
            name: name,
            type: showPassword === true ? "text" : type,
            value: value,
            onChange: onChange,
            onPaste: (e) => {
              disablePaste ? e.preventDefault() : TriggerInputEvent();
            },
            onFocus: (e) => {
              !focus && setFocus(true);
              TriggerInputEvent();
            },
            onBlur: () => {
              HandleOnBlur(props);
              TriggerInputEvent();
            },
            ...InputLogicConfig,
          }}
        />
        {type === "password" ? (
          <Button
            onClick={() => setShowPassword(!showPassword)}
            className={styles.utilityIcon}
          >
            {showPassword === true ? (
              <Icon icon="Eye" size="Small" className={styles.uIcon} />
            ) : (
              <Icon icon="EyeOff" size="Small" className={styles.uIcon} />
            )}
          </Button>
        ) : null}
      </div>
      <Typography
        component="p"
        variant="h200-medium"
        className={`${styles.errorMessage} ${
          (error || errorMessage) && styles.visible
        }`}
      >
        {errorMessage ? errorMessage : <LocaleAsString id={error} />}
      </Typography>
    </div>
  );
};
//
export default InputComponent;
