const ErrorMessages = {
  required: "forms.validations.required",
  email: "forms.validations.email",
  repeatEmail: "forms.validations.repeat_email",
  repeatPassword: "forms.validations.repeat_password",
  "+47": "forms.validations.norway_prefix_phone",
  Norway: "forms.validations.norway_country_postal_code",
};

const Validations = {
  "+47":
    /^[+]?(?=(?:[^\dx]*\d){8}$)(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?)(?:[ -]?(?:\(\d+(?:\.\d+)?\)|\d+(?:\.\d+)?))*(?:[ ]?(?:x|ext)\.?[ ]?\d{1,5})?$/,
  Norway: /^\d{4}$/,
};

export { ErrorMessages, Validations };
