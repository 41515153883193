import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Icon from "../../icons";
import { Typography } from "../../utils";
import { SelectProps } from "./types";
import * as styles from "./styles.module.scss";

const Select = ({
  className,
  name = "Select",
  label = "Select",
  required = true,
  icon,
  options = [],
  onChange,
  value,
  withSelection,
  placeholder = "Select an option",
  showIcon = true,
}: SelectProps) => {
  const [flagIcon, setFlagIcon] = useState(null);

  const getFlag = () => {
    let flag = "";

    options.find((element) => {
      if (element.value === value) flag = element.flag.toLowerCase();
    });

    let flagicon = null;

    if (!flag) {
      flagicon = (
        <span className={styles.flag}>
          <span className={styles.flagIconWrapper}>
            <div className={styles.flagIcon} />
          </span>
        </span>
      );
    } else {
      flagicon = (
        <span className={styles.flag}>
          <span className={styles.flagIconWrapper}>
            <img
              width={"36px"}
              height={"24px"}
              className={styles.flagIcon}
              src={require(`../../../images/flags/${flag}.svg`)?.default}
            />
          </span>
        </span>
      );
    }

    setFlagIcon(flagicon);
  };

  const flags = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "flags" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `);

  const isFocused = true;

  useEffect(() => {
    if (name === "prefix" && value) {
      let flag = "";
      let staticFlagImg = "";

      options.find((element) => {
        if (element.value === value) flag = element.flag.toLowerCase();
        staticFlagImg = flags.allFile.nodes.find((el) => el.name === flag);
      });

      setFlagIcon(
        staticFlagImg?.publicURL ? (
          <span className={styles.flag}>
            <span className={styles.flagIconWrapper}>
              <img
                width={"36px"}
                height={"24px"}
                className={styles.flagIcon}
                src={staticFlagImg.publicURL}
              />
            </span>
          </span>
        ) : null
      );
    }
  }, [value]);

  return (
    <div className={`${styles.selectWrapper} ${className}`}>
      <div
        className={`${styles.select} ${!showIcon && styles.selectWithoutIcon}`}
      >
        {showIcon && (
          <Icon icon={icon} size={"Small"} className={styles.icon} />
        )}
        {flagIcon && showIcon ? flagIcon : null}
        <Typography
          component="label"
          variant={"h300-medium"}
          for={name}
          className={`${styles.label} ${isFocused && styles.focused} ${
            flagIcon && showIcon ? styles.withFlagIcon : null
          }`}
        >
          {label}
          {required && (
            <>
              {" "}
              <span title="required" aria-label="required">
                *
              </span>
            </>
          )}
        </Typography>
        <Typography
          className={`${styles.selectTag} ${flagIcon && styles.withFlagIcon}`}
          component="select"
          variant={"h400-medium"}
          inputLogic={{
            name: name,
            onChange: onChange,
            value: value,
          }}
        >
          <option value="">{placeholder}</option>
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </Typography>
        {withSelection && (
          <span className={styles.chevron}>
            <span className={styles.chevronIconWrapper}>
              <Icon
                icon="ChevronDown"
                size="Small"
                className={styles.chevronIcon}
              />
            </span>
          </span>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default Select;
