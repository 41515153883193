import React from "react";
import Icon from "../../icons";
import * as styles from "./styles.module.scss";

interface CheckboxProps {
  checked: boolean;
  setChecked: Function;
}

const Checkbox = ({ checked, setChecked }: CheckboxProps) => {
  return (
    <span
      role="checkbox"
      aria-checked={checked}
      tabIndex={0}
      aria-labelledby="chk1-label"
      className={`${styles.checkbox} ${checked && styles.checked}`}
      onKeyPress={(e) => {
        e.preventDefault();
        setChecked();
      }}
      onClick={() => setChecked()}
    >
      {checked && <Icon icon="Check" size="Small" />}
    </span>
  );
};

export default Checkbox;
