// extracted by mini-css-extract-plugin
export var selectWrapper = "styles-module--selectWrapper--3J2J8";
export var select = "styles-module--select--3y1oE";
export var selectWithoutIcon = "styles-module--selectWithoutIcon--lbv-2";
export var label = "styles-module--label--h_3EJ";
export var selectTag = "styles-module--selectTag--qNREm";
export var withFlagIcon = "styles-module--withFlagIcon--2qEX5";
export var icon = "styles-module--icon--2nc1w";
export var focused = "styles-module--focused--1RXNK";
export var withSelection = "styles-module--withSelection--PVHVb";
export var flag = "styles-module--flag--9A64R";
export var flagIconWrapper = "styles-module--flagIconWrapper--2KjDg";
export var flagIcon = "styles-module--flagIcon--3ifLJ";
export var chevron = "styles-module--chevron--3xbBG";
export var chevronIconWrapper = "styles-module--chevronIconWrapper--IKf74";
export var chevronIcon = "styles-module--chevronIcon--3ZRs6";